import { UpdatePickupPlan } from '~constants/schedule-pickup';
import { IRequestState as INewRequestState } from '~utils/request-state';
import { IUserSettings } from '../parent-child/types';

export enum PickupPlan {
    None = 0,
    Weekly = 1,
    ByRequest = 2,
    Commercial = 3,
}

export type PlanType = PickupPlan | UpdatePickupPlan;

export interface IUserPickupPlan {
    planType: PickupPlan;
    pickupDaysAsString: string;
    pickupDays: number[];
}

export interface IUserPickupPlanState extends INewRequestState {
    data: IUserPickupPlan;
}

export interface ISubscriberInfo {
    subscriberId: number;
    subscriberEmail: string;
    subscriberPhone: string;
    subscriberReferralCredit: number;
}

export enum EmailHealthcheckType {
    Bounce = 1,
    Complaint = 2,
}

export interface IEmailHealthcheck {
    warningSkip: boolean;
    email: string;
    type: EmailHealthcheckType;
}

export interface IAccountInfo extends ISubscriberInfo {
    customerHold: boolean;
    vacationHold: boolean;
    vacationHoldLoading: boolean;
    isCorrectBillingInfo: boolean;
    hasEnteredPromoCode: boolean;
    hasDeclinedInvoices: boolean;
    hasFailedPreAuths: boolean;
    emailHealthcheck: IEmailHealthcheck[];
    signupQuery: boolean;
    isParent: boolean;
    settings?: IUserSettings;
    enableDriverTips: boolean;
    vacationHoldEndDate?: string;
}

export interface VacationHoldForm {
    vacationHold: boolean;
    vacationHoldEndDate?: string;
}
