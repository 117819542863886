import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultErrorMessage, serviceUnavailableMessage, showErrorToast } from '~utils/toast-utils';
import { getAccessToken, getSignupToken } from '~utils/tokens';
import {
    isInternalServerError,
    isNoInternetError,
    isServiceUnavailableError,
    isUnauthorizedError,
} from '~utils/errors';
import { ToastIds } from '~constants/toast-ids';
import logger from '~utils/logger';
import { refresh } from '~utils/hn-api';

export const Method = {
    GET: 'GET',
    PATCH: 'PATCH',
    POST: 'POST',
    DELETE: 'DELETE',
};

const prepareHeaders = (headers: Headers) => {
    const accessToken = getAccessToken();
    const signupToken = getSignupToken();
    if (headers && !headers.has('Authorization')) {
        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`);
        } else if (signupToken) {
            headers.set('Authorization', `Bearer ${signupToken}`);
        }
    }
    return headers;
};

const hnBaseQuery = fetchBaseQuery({
    baseUrl: window.hnApi,
    prepareHeaders,
});

export const hnApiSlice = createApi({
    reducerPath: 'hn-api',
    baseQuery: async (args, api, extraOptions) => {
        let result = await hnBaseQuery(args, api, extraOptions);

        if (result.error) {
            if (isNoInternetError(result.error, true)) {
                showErrorToast(
                    'Something went wrong. Please check your internet connection or reload the page.',
                    ToastIds.NO_INTERNET_CONNECTION,
                );
            }

            if (isUnauthorizedError(result.error, true)) {
                const isSucceed = await refresh();
                if (isSucceed) {
                    result = await hnBaseQuery(args, api, extraOptions);
                }
            }

            if (isInternalServerError(result.error, true)) {
                showErrorToast(defaultErrorMessage, ToastIds.API_INTERNAL_ERROR);
                logger.error(
                    result.error?.data?.message
                        ? JSON.stringify(result.error?.data?.message)
                        : 'Internal server error detected',
                );
            }

            if (isServiceUnavailableError(result.error)) {
                showErrorToast(serviceUnavailableMessage, ToastIds.SERVICE_UNAVAILABLE);
            }
        }

        return result;
    },
    endpoints: () => ({}),
});

export const handleErrorToast =
    (requestId: string, defaultMessage: string = defaultErrorMessage) =>
    (error: any) => {
        let description = null;
        const rawMessage = error?.error?.data?.message as string | string[] | undefined;
        const rawDescription = error?.error?.data?.description as string | string[] | undefined;

        let message = defaultMessage;

        if (Array.isArray(rawDescription) && rawDescription.length > 0) {
            [description] = rawDescription;
        } else if (typeof rawDescription === 'string') {
            description = rawDescription;
        }

        if (Array.isArray(rawMessage) && rawMessage.length > 0) {
            [message] = rawMessage;
        } else if (typeof rawMessage === 'string') {
            message = rawMessage;
        }

        showErrorToast(description ?? message.trim(), requestId);
    };
