import { Stack } from '@mui/material';
import React from 'react';
import { PROD_APP_URL } from '~hooks/use-is-test-mode';
import Button from '~shared/button';
import DialogWrapper from '~shared/dialog-wrapper';
import Typography from '~shared/typography';

interface TestModeAlertDialogProps {
    show: boolean;
    closeDialog: () => void;
}

const TestModeAlertDialog = ({ show, closeDialog }: TestModeAlertDialogProps) => {
    const handleGoToRealApp = () => {
        window.location.replace(`https://${PROD_APP_URL}`);
    };

    return (
        <DialogWrapper
            open={show}
            title='Attention: This is a test website'
            dialogStyle={{ maxWidth: 500 }}
            dialogActions={
                <Button onClick={closeDialog} variant='outlined' id='continue-button'>
                    Continue
                </Button>
            }
        >
            <Stack py={3} gap={3} alignItems='center'>
                <Typography variant='body1' color='action' textAlign='center'>
                    This is a test version of the website that does not use real data and is
                    intended for internal testing only. <br />
                    <strong>Please use the official website if you get here accidentally.</strong>
                </Typography>

                <Button onClick={handleGoToRealApp} id='go-to-official-website-button'>
                    Go to the Official Website
                </Button>
            </Stack>
        </DialogWrapper>
    );
};

export default TestModeAlertDialog;
