import { Box } from '@mui/material';
import Shadows from '~constants/shadows';
import useBreakpoint from '~hooks/use-breakpoint';
import FooterDesktop from './footer-desktop';
import FooterMobile from './footer-mobile';
import style from './footer.style';

interface IFooter {
    showReferFriendButton?: boolean;
}

const Footer: React.FC<IFooter> = ({ showReferFriendButton = true }) => {
    const isDesktop = useBreakpoint();

    return (
        <Box boxShadow={Shadows.smallReverse} sx={style.root} data-testid='footer'>
            {isDesktop ? (
                <FooterDesktop showReferFriendButton={showReferFriendButton} />
            ) : (
                <FooterMobile />
            )}
        </Box>
    );
};

export default Footer;
