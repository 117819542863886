import { Typography as MuiTypography, TypographyProps, TypographyVariant } from '@mui/material';

export interface ITypography extends TypographyProps {
    variant?: TypographyVariant;
    color?: ColorList;
    testId?: string;
}

const Typography: React.FC<ITypography> = ({ children, testId, color, variant, sx, ...props }) => (
    <MuiTypography
        data-testid={testId}
        {...props}
        variant={variant}
        sx={{ color: `${color}.main`, ...sx }}
    >
        {children}
    </MuiTypography>
);

export default Typography;
