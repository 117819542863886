import { datadogLogs, LogsEvent, LogsInitConfiguration } from '@datadog/browser-logs';
import { getUserId, getSubscriberId } from './tokens';

function shouldWriteLogs() {
    return process.env.MODE !== 'dev';
}

const ignoreList = ['changes', 'localhost', 'googleadservices'];

const isXHRError = (message?: string) => !!message && message.includes('XHR');

// Allow XHR HappyNest only and all the not XHR errors
const isHappyNestXHRError = (message?: string): boolean =>
    !!message && (!message.includes('XHR') || message.includes('happynest'));

export const initDatadogLogClient = () => {
    if (shouldWriteLogs()) {
        datadogLogs.setGlobalContext({
            host: window.location.hostname,
        });

        let datadogConfig: LogsInitConfiguration = {
            clientToken: process.env.DATADOG_KEY as string,
            site: 'datadoghq.com',
            service: 'HN-REACT-APP',
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
            beforeSend: (log: LogsEvent) => {
                if (
                    !isHappyNestXHRError(log.message) ||
                    ignoreList.some((p) => log.message.includes(p))
                ) {
                    return false;
                }

                if (isXHRError(log.message)) {
                    log.status = 'warn';
                }

                if (log.http && log.http.status_code >= 200 && log.http.status_code < 500) {
                    return false;
                }

                return true;
            },
        };

        if (process.env.VERSION) {
            datadogConfig = { ...datadogConfig, version: process.env.VERSION };
        }

        const userId = getUserId();
        const subscriberId = getSubscriberId();

        datadogLogs.setUser({
            id: userId.toString(),
            subscriberId: subscriberId.toString(),
        });

        datadogLogs.init(datadogConfig);
    }
};
