import { LoadingButton } from '@mui/lab';
import {
    Box,
    Breakpoint,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    Slide,
    SlideProps,
    useTheme,
} from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import useBreakpoint from '~hooks/use-breakpoint';
import Button from '~shared/button';
import PageSpinner from '~shared/page-spinner';
import Typography from '~shared/typography';
import getStyle from './dialog-wrapper.style';

export interface IDialogWrapper {
    open: boolean;
    hidden?: DialogProps['hidden'];
    hideBackdrop?: DialogProps['hideBackdrop'];
    onClose?: () => void;
    onSave?: () => void;
    title?: ReactNode;
    warningSubtitle?: string;
    infoSubtitle?: JSX.Element | string;
    loading?: boolean;
    width?: Breakpoint;
    isDirty?: boolean;
    isValid?: boolean;
    dialogActions?: JSX.Element;
    dialogStyle?: Style['key'];
    dangerText?: string;
    isFullScreen?: boolean;
    useTransition?: boolean;
    ignoreFullscreen?: boolean;
    titleId?: string;
    contentLoading?: boolean;
}

const Transition = (props: SlideProps) => <Slide direction='right' {...props} />;

const DialogWrapper: React.FC<PropsWithChildren<IDialogWrapper>> = ({
    title,
    warningSubtitle,
    infoSubtitle,
    open,
    hidden,
    hideBackdrop,
    children,
    onClose,
    onSave,
    loading,
    width,
    dialogActions,
    dialogStyle = {},
    isDirty,
    isValid,
    dangerText,
    isFullScreen = false,
    useTransition = false,
    ignoreFullscreen = false,
    contentLoading = false,
    titleId = 'dialogTitle',
}) => {
    const isDesktop = useBreakpoint();
    const isTablet = useBreakpoint('sm');
    const style = getStyle(useTheme());

    let customHiddenStyle = {};

    if (hidden) {
        customHiddenStyle = {
            opacity: '0',
            visibility: 'hidden',
            transition: undefined,
        };
    } else if (hidden !== undefined) {
        customHiddenStyle = {
            opacity: undefined,
            visibility: undefined,
            transition: 'opacity 0.3s',
        };
    }

    const paperStyle = {
        ...style.paper,
        ...dialogStyle,
        ...customHiddenStyle,
    };

    const mobilePaperStyle = {
        ...style.mobilePaper,
        ...dialogStyle,
    };

    const handleClose = () => onClose?.();

    const getFullScreen = () => {
        if (ignoreFullscreen) {
            return false;
        }

        return isFullScreen || !(isDesktop || isTablet);
    };

    const getTransitionComponent = () => {
        if (ignoreFullscreen) {
            return undefined;
        }

        if (useTransition) {
            return Transition;
        }

        return !(isDesktop || isTablet) ? Transition : undefined;
    };

    const showTitleBox = !!title || !!warningSubtitle || !!infoSubtitle;

    return (
        <Dialog
            scroll={isDesktop || isTablet ? 'body' : undefined}
            maxWidth={width}
            PaperProps={{
                sx: isDesktop || isTablet || ignoreFullscreen ? paperStyle : mobilePaperStyle,
            }}
            fullWidth
            open={open}
            hideBackdrop={hideBackdrop}
            onClose={handleClose}
            role='dialog'
            fullScreen={getFullScreen()}
            TransitionComponent={getTransitionComponent()}
            data-testid='dialog-wrapper'
        >
            {dangerText && (
                <Box sx={style.dangerBox}>
                    <Typography align='center' variant='subtitle2' color='white'>
                        {dangerText}
                    </Typography>
                </Box>
            )}
            {showTitleBox && (
                <Box sx={style.title}>
                    {title && (
                        <Typography variant='h6' color='action' id={titleId}>
                            {title}
                        </Typography>
                    )}
                    {warningSubtitle && (
                        <Typography color='warning' variant='body1'>
                            {warningSubtitle}
                        </Typography>
                    )}
                    {typeof infoSubtitle === 'string' ? (
                        <Typography color='grey1' variant='body1'>
                            {infoSubtitle}
                        </Typography>
                    ) : (
                        infoSubtitle
                    )}
                </Box>
            )}
            {children && (
                <DialogContent sx={{ ...(contentLoading ? style.dialogContentLoading : {}) }}>
                    {contentLoading && (
                        <Box sx={style.absoluteBox}>
                            <PageSpinner height='100%' loadingDelayMs={250} />
                        </Box>
                    )}

                    <Box sx={{ ...(contentLoading ? style.opacityLess : {}) }}>{children}</Box>
                </DialogContent>
            )}
            {(dialogActions || onClose || onSave) && (
                <DialogActions disableSpacing sx={style.actions}>
                    {dialogActions || (
                        <>
                            {onClose && (
                                <Button
                                    id='close'
                                    onClick={onClose}
                                    variant='outlined'
                                    data-testid='close-button'
                                >
                                    Close
                                </Button>
                            )}
                            {onSave && (
                                <LoadingButton
                                    id='save'
                                    loading={loading}
                                    variant='contained'
                                    color='action'
                                    onClick={onSave}
                                    disabled={!isDirty || !isValid}
                                    data-testid='save-button'
                                >
                                    Save
                                </LoadingButton>
                            )}
                        </>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default DialogWrapper;
