import { RootState } from '~store/root-reducer';

export const selectUserSubscriberId = (state: RootState) =>
    state.personalInfo.userDetails.subscriberId;

export const selectUserDetails = (state: RootState) => state.personalInfo.userDetails;

export const selectFirstName = (state: RootState) => state.personalInfo.userDetails.firstName;

export const selectUserEmail = (state: RootState): string => state.personalInfo.userDetails.email;

export const selectReferrerCode = (state: RootState) => state.personalInfo.userDetails.referrerCode;

export const getIsEmailAvailabilityCheckLoading = (state: RootState) =>
    state.personalInfo.emailAvailability.isLoading;

export const getIsEmailAvailabilityCheckSucceed = (state: RootState) =>
    state.personalInfo.emailAvailability.isSucceed;

export const getEmailAvailabilityCheckResult = (state: RootState) =>
    state.personalInfo.emailAvailability.data;

export const getCreditAmount = (state: RootState) => state.personalInfo.userDetails.creditAmount;

export const getIsUserConstraintsFetchLoading = (state: RootState) =>
    state.personalInfo.userConstraints.isLoading;

export const getIsUserConstraintsFetchSucceed = (state: RootState) =>
    state.personalInfo.userConstraints.isSucceed;

export const getUserConstraints = (state: RootState) => state.personalInfo.userConstraints.data;

export const getSendDeleteAccountCodeState = (state: RootState) =>
    state.personalInfo.sendDeleteAccountCode;

export const getDeleteAccountState = (state: RootState) => state.personalInfo.deleteAccount;
