import { Box, BoxProps, CircularProgress, CircularProgressProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

enum PageSpinnerSize {
    lg = '5rem',
    md = '2rem',
    sm = '1rem',
    xs = '0.8rem',
    default = '2.5rem',
}

type PageSpinnerSizeKey = keyof typeof PageSpinnerSize;

interface PageSpinnerProps extends CircularProgressProps {
    size?: PageSpinnerSizeKey;
    loadingDelayMs?: number;
    height?: BoxProps['height'];
}

const PageSpinner = ({
    size = 'default',
    loadingDelayMs = 0,
    height = 300,
    ...restProps
}: PageSpinnerProps) => {
    const [showComponent, setShowComponent] = useState(loadingDelayMs === 0);

    useEffect(() => {
        let timeoutId = null;
        if (loadingDelayMs > 0) {
            timeoutId = setTimeout(() => setShowComponent(true), loadingDelayMs);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [loadingDelayMs]);

    return showComponent ? (
        <Box
            display='flex'
            height={height}
            alignItems='center'
            justifyContent='center'
            data-testid='page-spinner'
        >
            <CircularProgress size={PageSpinnerSize[size]} {...restProps} />
        </Box>
    ) : null;
};

export default PageSpinner;
