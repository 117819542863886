import { useEffect } from 'react';
import { selectUserDetails } from '~store/modules/personal-info/selectors';
import useAppSelector from './use-app-selector';

export const useHotjarUser = () => {
    const user = useAppSelector(selectUserDetails);

    useEffect(() => {
        if (window.hj && user) {
            window.hj('identify', user.userId, {
                email: user.email,
                userFullName: `${user.firstName} ${user.lastName}`,
            });
        }
    }, [user]);
};
