import { Box, Link, Typography } from '@mui/material';
import { FC } from 'react';
import HnLogoSmall from '~assets/svg/hn-logo-small.svg';
import HnLogo from '~assets/svg/hn-logo.svg';
import { useIsTestMode } from '~hooks/use-is-test-mode';
import style from './logo.style';

interface ILogo {
    isMobile?: boolean;
}

const Logo: FC<ILogo> = ({ isMobile }) => {
    const isTestMode = useIsTestMode();
    const logoIcon = isMobile ? <HnLogoSmall /> : <HnLogo />;

    return (
        <Box sx={style.hnLogo} data-testid='happyNest-logo'>
            <Link id='happyNestLink' href='https://happynest.com/'>
                {logoIcon}
            </Link>
            {isTestMode && (
                <Typography variant='h5' color='error' fontWeight='bold'>
                    TEST
                </Typography>
            )}
        </Box>
    );
};

export default Logo;
