import DialogTypes from '~constants/dialog-types';
import useAppDispatch from '~hooks/use-app-dispatch';
import useBreakpoint from '~hooks/use-breakpoint';
import Button from '~shared/button';
import { ReferFriendIcon } from '~shared/icons';
import { useSubscriberReferralCredit } from '~store/modules/account-info/selectors';
import { openDialog } from '~store/modules/dialog';

const ReferFriendButton = () => {
    const { longReferralCreditLabel, referralCredit } = useSubscriberReferralCredit();

    const isIcon = useBreakpoint('lg');
    const dispatch = useAppDispatch();

    const openReferFriendDialog = () => {
        dispatch(openDialog(DialogTypes.referFriend));
    };

    if (!referralCredit) {
        return null;
    }

    return (
        <Button
            id='referFriendButton'
            data-testid='referFriendButton'
            onClick={openReferFriendDialog}
            endIcon={isIcon && <ReferFriendIcon />}
            variant='contained'
            sx={{ whiteSpace: 'nowrap' }}
        >
            {longReferralCreditLabel}
        </Button>
    );
};

export default ReferFriendButton;
