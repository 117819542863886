import { IUserLaundryPreferences } from '~constants/laundry-preferences';
import { IUserRates } from '~constants/rates';
import { IRequestState, RequestStatus } from '~utils/request-state';
import { IUserDetails } from '../personal-info/types';
import { INextPickup, IPickup } from '../pickups/types';

export interface ISettings {
    overrideBillingInfo?: boolean;
    overrideLaundryPreferences?: boolean;
    overrideWashingPreferences?: boolean;
    overrideFoldingPreferences?: boolean;
}

export interface IUserSettings extends ISettings {
    FK_PK_usersID: number;
}

export interface IChildSettingsState extends IRequestState {
    settings: IUserSettings;
}

export interface IChildSettings extends IUserSettings {
    userDetails?: IUserDetails;
}

export interface IChildLaundryPreferencesState extends IRequestState {
    userId: number;
    laundryPreferences?: IUserLaundryPreferences;
}

export interface IChildRateState extends IRequestState {
    userId: number;
    rate?: IUserRates;
}

export interface IChildState extends IRequestState {
    user: IUserDetails;
    isCorrectBillingInfo?: boolean;
}

export interface IPickupResponse {
    userId: number;
    pickupDates?: IPickup[];
    progressStatus?: INextPickup;
}

export interface IPickupState extends IRequestState, IPickupResponse {
    progressStatusRequestStatus: RequestStatus;
}

export interface IChildPickups extends IPickupResponse {
    userDetails?: IUserDetails;
}

export interface IPickupPayload {
    userId: number;
    hideLoading?: boolean;
}

export enum PaymentStatus {
    Unpaid = 0,
    Paid = 1,
    Accrued = 2,
    Declined = 4,
    Voided = 10,
    Refunded = 11,
    PartialRefund = 12,
}

export const getPaymentStatusAsString = (status: PaymentStatus) => {
    switch (status) {
        case PaymentStatus.Unpaid:
            return 'Not Paid';
        case PaymentStatus.Paid:
            return 'Paid';
        case PaymentStatus.Accrued:
            return 'Accrued';
        case PaymentStatus.Declined:
            return 'Declined';
        case PaymentStatus.Voided:
            return 'Voided';
        case PaymentStatus.Refunded:
            return 'Refunded';
        case PaymentStatus.PartialRefund:
            return 'Partial Refund';
        default:
            return '';
    }
};

export interface IInvoiceResponse {
    id: number;
    FK_PK_usersID: number;
    invoiceDate: string;
    paymentStatus: PaymentStatus;
    total: number;
    isMissed: boolean;
}

export interface IInvoiceState extends IRequestState {
    invoices: IInvoiceResponse[];
}

export interface DropOff {
    PK_dropOffsID: number;
    scheduledDropOffDate: string;
    firstname?: string;
    lastname?: string;
    showCompanyName?: number;
    companyName?: string;
}

export interface IDropOffResponse {
    userId: number;
    dropOffs?: DropOff[];
}

export interface IDropOffState extends IRequestState, IDropOffResponse {}

export interface ISameDayCancelPayload {
    userId: number;
    toastMessage?: string;
}
