const style: Style = {
    hnLogo: {
        lineHeight: 0,
        p: 1,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
    },
};

export default style;
