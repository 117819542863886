import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'react-image-lightbox/style.css';
import useAppDispatch from '~hooks/use-app-dispatch';
import useAppSelector from '~hooks/use-app-selector';
import AuthenticatedRoutes from '~routes/authenticated-routes';
import NotAuthenticatedRoutes from '~routes/not-authenticated-routes';
import { getAccountInfoAsync } from '~store/modules/account-info';
import { getUserLoggedIn } from '~store/modules/auth/selectors';
import { selectUserEmail } from '~store/modules/personal-info/selectors';
import { sendImpactIdentifyInfo } from '~utils/marketing';
import { getUserId } from '~utils/tokens';
import TestModeAlertDialog from '~components/test-mode-alert-dialog';
import { useIgnoreTestModeDialog } from '~hooks/use-is-test-mode';
import { useHotjarUser } from '~hooks/use-hotjar-user';

const App = () => {
    const dispatch = useAppDispatch();
    const userLoggedIn = useAppSelector(getUserLoggedIn);
    const userEmail = useAppSelector(selectUserEmail);
    const userId = getUserId();
    const { closeDialog, showDialog } = useIgnoreTestModeDialog();

    useHotjarUser();

    useEffect(() => {
        sendImpactIdentifyInfo(userId, userEmail);
    }, [userId, userEmail]);

    const [searchParams] = useSearchParams();
    const isLoginByAdmin = !!searchParams.get('token') && !!searchParams.get('userId');

    useEffect(() => {
        if (userLoggedIn && !isLoginByAdmin) {
            dispatch(getAccountInfoAsync());
        }
    }, [dispatch, userLoggedIn]);

    return (
        <>
            {userLoggedIn ? <AuthenticatedRoutes /> : <NotAuthenticatedRoutes />}
            <TestModeAlertDialog show={showDialog} closeDialog={closeDialog} />
        </>
    );
};

export default App;
