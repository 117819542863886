enum DialogTypes {
    onboarding = 'onboarding',
    confirmation = 'confirmation',
    personalInfo = 'personalInfo',
    pickupAndDelivery = 'pickupAndDelivery',
    billingInfo = 'billingInfo',
    laundryPreferences = 'laundryPreferences',
    invoices = 'invoices',
    referFriend = 'referFriend',
    notifications = 'notifications',
    passwordUpdate = 'passwordUpdate',
    schedulePickup = 'schedulePickup',
    scheduleChildPickup = 'scheduleChildPickup',
    cancelPickup = 'cancelPickup',
    cancelChildPickup = 'cancelChildPickup',
    cancelSameDayPickup = 'cancelSameDayPickup',
    cancelChildSameDayPickup = 'cancelChildSameDayPickup',
    vacationHoldResume = 'vacationHoldResume',
    driverTipping = 'driverTipping',
    spamEmail = 'spamEmail',
    cancelAvailablePickup = 'cancelAvailablePickup',
    cancelNextStop = 'cancelNextStop',
    deleteAccount = 'deleteAccount',
    scheduleCalendar = 'scheduleCalendar',
    specialInstructions = 'specialInstructions',
    billingInfoConfirmation = 'billingInfoConfirmation',
    laundryPreferencesConfirmation = 'laundryPreferencesConfirmation',
    notificationConfirmation = 'notificationConfirmation',
    personalInfoConfirmation = 'personalInfoConfirmation',
    pickupAndDeliveryConfirmation = 'pickupAndDeliveryConfirmation',
    specialInstructionsConfirmation = 'specialInstructionsConfirmation',
    cancelAlternativePickup = 'specialAlternativePickup',
    driverLocation = 'driverLocation',
    tippingPreferences = 'tippingPreferences',
    automatedTippingConfirmation = 'automatedTippingConfirmation',
    tippingPreferencesConfirmation = 'tippingPreferencesConfirmation',
    cancelRewashPickup = 'cancelRewashPickup',
    exitIntent = 'exitIntent',
    promoCodes = 'promoCodes',
}

export const isDialog = (dialog: string | DialogTypes): boolean =>
    Object.values(DialogTypes).includes(dialog as DialogTypes);

export default DialogTypes;
