import { useCallback, useState } from 'react';
import Cookies from 'js-cookie';

export const PROD_APP_URL = 'app.happynest.com';
export const useIsTestMode = () => !window.location.href.includes(PROD_APP_URL);

export const useIgnoreTestModeDialog = () => {
    const isTestMode = useIsTestMode();

    const [ignoreTestMode, setIgnoreTestMode] = useState<boolean>(
        Cookies.get('ignoreTestMode') === 'true',
    );

    const ignoreTestModeHandler = useCallback(() => {
        Cookies.set('ignoreTestMode', 'true', { expires: 1 });
        setIgnoreTestMode(true);
    }, []);

    return {
        closeDialog: ignoreTestModeHandler,
        showDialog: isTestMode && !ignoreTestMode,
    };
};
