import { Id, toast, ToastContent } from 'react-toastify';
import { ToastIds } from '~constants/toast-ids';
import {
    isBadRequestError,
    isConflictError,
    isInternalServerError,
    isNoInternetError,
    isNotFoundError,
    isServiceUnavailableError,
} from './errors';

export const defaultErrorMessage = 'Something went wrong. Please try again later.';
export const serviceUnavailableMessage = 'Service unavailable, please try again later.';

const getErrorText = (error) => {
    if (isServiceUnavailableError(error)) {
        return serviceUnavailableMessage;
    }

    if (isBadRequestError(error) || isNotFoundError(error) || isConflictError(error)) {
        return error?.response?.data?.message ?? defaultErrorMessage;
    }

    return defaultErrorMessage;
};

export const getDescriptiveErrorText = (error) =>
    error?.response?.data?.description ?? defaultErrorMessage;

export const showApiErrorToast = (error, toastId) => {
    if (!isNoInternetError(error) && !isInternalServerError(error)) {
        if (isServiceUnavailableError(error)) {
            toastId = ToastIds.SERVICE_UNAVAILABLE;
        }

        if (!toast.isActive(toastId)) {
            toast.dismiss();
            toast.clearWaitingQueue();
            toast.error(getErrorText(error), {
                toastId,
            });
        }
    }
};

export const showMessageToast = (message, toastId) => {
    if (!toast.isActive(toastId)) {
        toast.dismiss();
        toast.clearWaitingQueue();
        toast(message, {
            toastId,
        });
    }
};

export const showErrorToast = (message: ToastContent, toastId: Id) => {
    if (!toast.isActive(toastId)) {
        toast.dismiss();
        toast.clearWaitingQueue();
        toast.error(message, {
            toastId,
        });
    }
};

export const clearToastQueue = () => {
    toast.dismiss();
    toast.clearWaitingQueue();
};
