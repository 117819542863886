import { createTheme, ThemeOptions } from '@mui/material';
import RadioOffIcon from '~assets/svg/radio-off.svg';
import RadioOnIcon from '~assets/svg/radio-on.svg';
import Shadows from '~constants/shadows';

const theme = createTheme({
    palette: {
        black: {
            main: '#0f284c',
        },
        grey1: {
            main: '#8795a7',
        },
        grey2: {
            main: '#e0e6ed',
        },
        grey3: {
            main: '#f0f3f6',
        },
        grey4: {
            main: '#fafbfc',
        },
        white: {
            main: '#ffffff',
        },
        action: {
            main: '#1d4687',
        },
        actionHover: {
            main: '#0493c8',
        },
        actionClick: {
            main: '#25a2d8',
        },
        link: {
            main: '#2875bc',
        },
        warning: {
            main: '#ff613d',
        },
        success: {
            main: '#43b886',
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        h1: {
            fontSize: 96,
            lineHeight: '112px',
            letterSpacing: -2,
        },
        h2: {
            fontSize: 60,
            lineHeight: '72px',
            letterSpacing: -0.5,
        },
        h3: {
            fontSize: 48,
            lineHeight: '56px',
            letterSpacing: -0.5,
        },
        h4: {
            fontSize: 34,
            lineHeight: '40px',
            letterSpacing: 0.5,
        },
        h5: {
            fontSize: 24,
            lineHeight: '32px',
            letterSpacing: -0.25,
        },
        h6: {
            fontSize: 20,
            lineHeight: '24px',
            letterSpacing: 0,
            fontWeight: 600,
        },
        subtitle1: {
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: 0,
            fontWeight: 600,
        },
        subtitle2: {
            fontSize: 14,
            lineHeight: '24px',
            letterSpacing: 0,
            fontWeight: 600,
        },
        body1: {
            fontSize: 16,
            lineHeight: '28px',
            letterSpacing: 0,
        },
        body2: {
            fontSize: 14,
            lineHeight: '20px',
        },
        button: {
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: 0,
            fontWeight: 600,
        },
        caption: {
            fontSize: 12,
            lineHeight: '16px',
            letterSpacing: 0.4,
        },
        columnLabel: {
            fontSize: 12,
            lineHeight: '16px',
            letterSpacing: 0.4,
            textTransform: 'uppercase',
            fontWeight: 500,
        },
        overline: {
            fontSize: 10,
            lineHeight: '16px',
            letterSpacing: 1.5,
            textTransform: 'uppercase',
            fontWeight: 600,
        },
        quote: {
            fontSize: 24,
            lineHeight: '36px',
            letterSpacing: -0.25,
            textTransform: 'uppercase',
            fontWeight: 600,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*::-webkit-scrollbar': {
                    width: '16px',
                    height: '16px',
                },
                '*::-webkit-scrollbar-track': {
                    background: 'transparent',
                },
                '*::-webkit-scrollbar-thumb': {
                    background: '#E0E6ED',
                    borderRadius: '8px',
                    border: '4px solid transparent',
                    backgroundClip: 'padding-box',
                },

                '*::-webkit-scrollbar-track:hover': {
                    background: 'transparent',
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    background: '#8795A7',
                    borderRadius: '8px',
                },

                '*::-webkit-scrollbar-track:active': {
                    background: '#E0E6ED',
                    borderRadius: '8px',
                },
                '*::-webkit-scrollbar-thumb:active': {
                    background: '#8795A7',
                    borderRadius: '8px',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#0f284c',

                    '&.Mui-focused': {
                        color: '#0f284c',
                    },
                },
            },
        },
        MuiPagination: {
            defaultProps: {
                color: 'action',
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: 'white',

                        '&:hover': {
                            backgroundColor: '#1d4687',
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    padding: '12.5px 16px',
                    backgroundColor: '#fff',
                    '&:hover': {
                        '> .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#8795a7',
                        },
                    },
                    '&.Mui-focused': {
                        '> .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1d4687',
                        },
                    },
                    '&.Mui-error': {
                        '> .MuiOutlinedInput-input': {
                            color: '#ff613d',
                        },
                        '> .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ff613d',
                        },
                    },
                },
                notchedOutline: {
                    borderColor: '#1d4687',
                },
                input: {
                    padding: 0,
                    color: '#0f284c',
                    caretColor: '#0f284c',

                    '&::placeholder': {
                        color: '#8795a7',
                    },
                },
                inputAdornedEnd: {
                    paddingRight: '0',
                },
                adornedEnd: {
                    paddingRight: '8px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    marginTop: '-3px',
                    color: '#8795a7',

                    '&.MuiInputLabel-shrink': {
                        color: '#1d4687',
                    },
                    '&.Mui-error': {
                        color: '#ff613d',
                    },
                    '&.Mui-disabled': {
                        color: '#8795a7',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#ff613d',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
                color: 'action',
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    fontSize: 16,
                    lineHeight: '24px',
                    letterSpacing: 0,
                    fontWeight: 500,
                    textTransform: 'none',
                    padding: '11px 24px',

                    '&.Mui-disabled': {
                        color: '#8795a7',
                    },
                },
                containedAction: {
                    color: '#ffffff',
                    backgroundColor: '#1d4687',
                    '&:hover, &:active': {
                        '@media (hover: hover) and (pointer: fine)': {
                            filter: 'brightness(0.95)',
                        },
                    },
                },
                textBlack: {
                    color: '#0f284c',

                    '&:hover, &:active': {
                        '@media (hover: hover) and (pointer: fine)': {
                            filter: 'brightness(0.95)',
                        },
                    },
                },
                outlinedAction: {
                    color: '#1d4687',
                    backgroundColor: '#fff',
                    border: '1px solid #1d4687',

                    '& > .MuiButton-startIcon': {
                        '> *:nth-of-type(1)': {
                            color: '#1d4687',
                        },
                    },

                    '&:hover': {
                        '@media (hover: hover) and (pointer: fine)': {
                            filter: 'brightness(0.95)',
                        },
                        '& > .MuiButton-startIcon': {
                            '> *:nth-of-type(1)': {
                                color: '#1d4687',
                            },
                        },
                    },
                    '&:active': {
                        color: '#1d4687',
                        border: '1px solid #1d4687',

                        '& > .MuiButton-startIcon': {
                            '> *:nth-of-type(1)': {
                                color: '#1d4687',
                            },
                        },
                    },
                },
                textAction: {
                    padding: 0,
                    color: '#1d4687',
                    '&:hover, &:active': {
                        '@media (hover: hover) and (pointer: fine)': {
                            background: 'none',
                            opacity: '0.400831677',
                        },
                    },
                },
                textError: {
                    '&:hover, &:active': {
                        '@media (hover: hover) and (pointer: fine)': {
                            opacity: '0.400831677',
                            background: 'none',
                        },
                    },
                },
                iconSizeMedium: {
                    '> *:nth-of-type(1)': {
                        fontSize: '24px',
                    },
                },
            },
        },

        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.MuiDateRangePickerDay-day': {
                        '&.Mui-selected': {
                            backgroundColor: '#1d4687',
                        },
                    },
                },
            },
        },

        MuiIconButton: {
            disableElevation: true,
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    padding: '4px',
                },
                indicator: {
                    backgroundColor: '#1d4687',
                },
                flexContainer: {
                    gap: '4px',
                    zIndex: 1,
                    position: 'relative',
                },
            },
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    color: '#8795a7',
                    padding: '10px 20px',
                    fontSize: 16,
                    lineHeight: '24px',
                    letterSpacing: 0,
                    fontWeight: 500,
                    borderRadius: '4px',

                    ':hover': {
                        backgroundColor: '#f0f3f6',
                    },

                    '&.Mui-selected': {
                        color: '#1d4687',

                        ':hover': {
                            background: 'none',
                        },
                    },
                },
            },
        },
        // TODO: Sorting button clarification
        MuiDataGrid: {
            defaultProps: {
                rowHeight: 80,
                disableSelectionOnClick: true,
                rowSpacingType: 'border',
                showColumnRightBorder: false,
                disableColumnMenu: true,
                autoHeight: true,
                disableColumnResize: true,
                hideFooter: true,
                components: {
                    ColumnResizeIcon: () => null,
                },
            },
            styleOverrides: {
                root: {
                    border: 'none',
                },
                main: {
                    overflow: 'visible',

                    '& .MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
                        maxHeight: 'unset',
                        outline: 'none !important',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        color: '#0f284c',
                    },

                    '& .MuiDataGrid-columnHeader': {
                        fontSize: '12px',
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        userSelect: 'none',
                    },

                    '& .MuiDataGrid-cell': {
                        fontSize: '16px',
                    },

                    '& .MuiDataGrid-virtualScroller': {
                        boxShadow: Shadows.medium,

                        '&::-webkit-scrollbar': {
                            width: '16px',
                            height: '16px',

                            '@supports (-webkit-touch-callout: none)': {
                                /* CSS specific to iOS devices */
                                appearance: 'none',
                                overflow: 'scroll',
                            },
                        },

                        '&::-webkit-scrollbar-track': {
                            background: 'transparent',
                        },

                        '&::-webkit-scrollbar-thumb': {
                            background: '#E0E6ED',
                            borderRadius: '8px',
                            border: '4px solid transparent',
                            backgroundClip: 'padding-box',
                        },

                        '&::-webkit-scrollbar-track:hover': {
                            background: 'transparent',
                        },

                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#8795A7',
                            borderRadius: '8px',
                        },

                        '&::-webkit-scrollbar-track:active': {
                            background: '#E0E6ED',
                            borderRadius: '8px',
                        },

                        '&::-webkit-scrollbar-thumb:active': {
                            background: '#8795A7',
                            borderRadius: '8px',
                        },
                    },

                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'collapse',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-root': {
                        color: '#0f284c',
                        lineHeight: '16px',
                        fontSize: '12px',
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        paddingBottom: '10px',
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-root': {
                        borderBottom: 'none',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#0f284c',
                    padding: '12px',
                    fontSize: '16px',
                    lineHeight: '24px',
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    color: '#1d4687',
                    padding: 0,

                    '&.Mui-checked:hover': {
                        color: '#1d4687',
                        '@media (hover: hover) and (pointer: fine)': {
                            opacity: '0.400831677',
                        },
                    },

                    '&.Mui-disabled': {
                        g: {
                            fill: '#f0f3f6',
                        },

                        '&.Mui-checked': {
                            g: {
                                fill: 'none',
                            },
                        },
                    },
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                color: 'action',
                disableRipple: true,
                checkedIcon: <RadioOnIcon />,
                icon: <RadioOffIcon />,
            },
            styleOverrides: {
                root: {
                    padding: '12px',
                    backgroundColor: 'transparent !important',
                },
                colorAction: {
                    color: '#1d4687',

                    '&.Mui-checked:hover': {
                        color: '#1d4687',
                        '@media (hover: hover) and (pointer: fine)': {
                            opacity: '0.400831677',
                        },
                    },

                    '&.Mui-disabled': {
                        color: '#e0e6ed',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-root': {
                        padding: '12.5px 16px',

                        '.MuiAutocomplete-input': {
                            padding: 0,
                        },
                    },
                },
                paper: {
                    boxShadow: Shadows.large,
                },
                listbox: {
                    '.MuiAutocomplete-option': {
                        padding: '12px 16px !important',

                        '&:hover': {
                            backgroundColor: '#f0f3f6',
                            cursor: 'pointer',
                        },
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
                select: {
                    padding: '12.5px 16px',
                },
            },
        },
    },
} as ThemeOptions);

export default theme;
