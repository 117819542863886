import { Theme } from '@mui/material';

const sideMargin = 2;

const style = (theme: Theme): Style => ({
    paper: {
        borderRadius: '16px',
        [theme.breakpoints.down('sm')]: {
            mx: sideMargin,
        },
        '&.MuiDialog-paperScrollBody': {
            [theme.breakpoints.down('sm')]: {
                maxWidth: `calc(100% - ${theme.spacing(sideMargin)})`,
                width: 'auto',
            },
        },
    },
    mobilePaper: {
        justifyContent: 'space-between',

        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
        },
    },
    title: {
        pt: 5,
        pb: 1,
        px: 2,
        textAlign: 'center',
        typography: {
            variant: 'h5',
        },
    },
    actions: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 2,
        backgroundColor: 'grey4.main',

        [theme.breakpoints.up('sm')]: {
            py: 2,
            px: 4,
        },
        [theme.breakpoints.down('sm')]: {
            p: 2,
        },
    },
    dangerBox: {
        backgroundColor: 'red',
        py: 1.5,
    },

    opacityLess: {
        filter: 'opacity(70%)',
    },

    absoluteBox: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        zIndex: 999,
        height: '100%',
        position: 'absolute',
    },

    dialogContentLoading: {
        pointerEvents: 'none',
        position: 'relative',
    },
});

export default style;
